import React from 'react';
import { Typography, Box, CssBaseline, GlobalStyles } from '@mui/material';
import Header from './Header';
import Footer from "./Footer";

function MainLayout({ children }) {
    return (
        <>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
                    html: { height: '100%' },
                    body: { height: '100%', margin: 0, padding: 0 },
                    '#root': { height: '100%' },
                }}
            />
            <Box sx={{ flexGrow: 1, p: 0 }}>
                <Header />
                {children}
                <Footer/>
            </Box>
        </>
    );
}

export default MainLayout;
