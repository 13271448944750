import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function KareoModal({ open, handleClose }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xl"
            aria-labelledby="kareo-modal-title"
            aria-describedby="kareo-modal-description"
        >
            <DialogTitle id="kareo-modal-title">
                Appointment Portal
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                    <iframe
                        src="https://portal.kareo.com/app/new/login"
                        title="Kareo Portal"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            border: 0,
                        }}
                        allowFullScreen
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default KareoModal;
