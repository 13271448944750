import React from 'react';
import {Box, Container, Grid, Typography} from "@mui/material";

const Services = () => {
    return (
        <>
            {/* Jumbotron Section */}
            <Box sx={{ backgroundColor: '#d3d2d2', padding: '50px 0' }}>
                <Container>
                    <Typography variant="h2" align="center" gutterBottom>
                        SERVICES
                    </Typography>
                    <Typography variant="h5" align="center">
                        FAMILY HEALTH NURSING PRACTICE
                    </Typography>
                </Container>
            </Box>

            {/* Services Section */}
            <Container sx={{ paddingY: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="immunization" gutterBottom>
                                IMMUNIZATION
                            </Typography>
                            <Typography variant="body1">
                                Access to adults and children's routine vaccines. For example, FLU vaccine, Varicella, Pneumococcal, Shingles vaccine etc.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="chm" gutterBottom>
                                CHRONIC HEALTH MANAGEMENT
                            </Typography>
                            <Typography variant="body1">
                                Here we manage a wide variety of chronic medical conditions such as diabetes, hypertension, hypothyroidism, Asthma/COPD, Osteoarthritis, etc.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="mmc" gutterBottom>
                                MEDICAL MARIJUANA CERTIFICATION
                            </Typography>
                            <Typography variant="body1">
                                Eligible candidates who meet the NYS MMP criteria can become certified via a simple and easy process. Please visit the New York State Medical Marijuana Program for further information, or call the office to speak with our knowledgeable provider about this topic. We offer in-depth natural pain management with the use of medical marijuana as an alternative to opioids.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="std" gutterBottom>
                                STD SCREENING
                            </Typography>
                            <Typography variant="body1">
                                We provide screening for Gonorrhea, Chlamydia, Syphilis, and other STDs. Access to quick, rapid results on common STDs such as HIV.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="mac" gutterBottom>
                                MINOR ACUTE CARE
                            </Typography>
                            <Typography variant="body1">
                                No need to wait in line in a busy emergency room or urgent care for minor issues such as a sprain, minor lacerations, ear infections, sinusitis, etc. We provide quick access to care and get you back to your routine functions in no time.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="hc" gutterBottom>
                                HOUSE CALLS
                            </Typography>
                            <Typography variant="body1">
                                Don't feel like leaving your home to see the doctor? Our house call program is set up to do just that. A medical provider comes right to your house. This service is ideal for elderly people who have decreased levels of functioning. A great service during the pandemic or other emergencies.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="house" gutterBottom>
                                HOME CARE
                            </Typography>
                            <Typography variant="body1">
                                Our special home care service provides home health aides and certified nursing assistants for one-on-one care to elderly patients, adults, and children who have special care needs.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="smoke" gutterBottom>
                                SMOKING CESSATION
                            </Typography>
                            <Typography variant="body1">
                                Treatment and counseling for smoking cessation.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="telehealth" gutterBottom>
                                TELEHEALTH
                            </Typography>
                            <Typography variant="body1">
                                Our unique tele audio and video features are extremely popular and allow quick access to a general practitioner for treatment and management of health services.
                            </Typography>
                        </Box>

                        <Box sx={{ marginBottom: 4 }}>
                            <Typography variant="h5" id="pha" gutterBottom>
                                PHYSICAL HEALTH ASSESSMENTS
                            </Typography>
                            <Typography variant="body1">
                                In-depth examination and analysis of physical health.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Services;