import React, {useState} from 'react';
import {Container, Typography, Box, Grid, Collapse, Button} from '@mui/material';
import services from '../images/general/services.jpg';
import doctorw from '../images/general/doctorw.jpg';
function About() {

    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    return (
        <>
            {/* Jumbotron Section */}
            <Box sx={{backgroundColor: '#d3d2d2', padding: '50px 0'}}>
                <Container>
                    <Typography variant="h2" align="center" gutterBottom>
                        ABOUT US
                    </Typography>
                    <Typography variant="h5" align="center">
                        FAMILY HEALTH NURSING PRACTICE
                    </Typography>
                </Container>
            </Box>

            {/* Our Mission Section */}
            <Container sx={{paddingY: 4}}>
                <Typography variant="h4" align="center" gutterBottom>
                    OUR MISSION AT FHNP
                </Typography>
                <Typography variant="body1" align="center">
                    Family Health NP Practice (FHNP) is committed to providing affordable, high-quality, evidence-based
                    care to a diverse population, especially the underserved.
                </Typography>
            </Container>

            {/* Meet Dr. Whyte Section */}
            <Box sx={{paddingY: 4, backgroundColor: '#fafafa'}}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom>
                        MEET DR. WHYTE
                    </Typography>
                </Container>

                <Container>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img src={doctorw} alt="Dr. Beverly Whyte"
                                 style={{width: '100%', height: 'auto'}}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" gutterBottom>
                                Dr. Beverly Whyte is a family health nurse practitioner with over 10 years of experience
                                in a variety of settings including emergency room, family practice, women’s health, and
                                long-term care to name a few. With a Doctoral degree in Nursing Practice, she has the
                                knowledge, skills, abilities, and essential competencies to deliver high-quality care
                                using an evidence-based model approach to improve health outcomes for her patients. As
                                an immigrant from Jamaica who came from a humble beginning, she is culturally sensitive
                                and competent, and positions herself to ensure “no one is left behind” who seeks health
                                care services regardless of their circumstances. Dr. Whyte also has a passion for
                                chronic pain management which led to the fulfillment of her doctoral degree with a
                                research study on the effectiveness of medical marijuana for adults in chronic pain.
                            </Typography>
                            <Typography variant="body1">
                                As an advanced nurse practitioner with a doctoral degree, she is able to fill the gap
                                between primary care physicians and patients who are unable to find a doctor. She
                                believes waiting months in advance to see doctors is not in alignment with the goal of
                                healthcare. She is committed to serve and to ensure everyone is treated respectfully in
                                her small, but comfortable practice.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* What You'll Experience Section */}
            <Container sx={{paddingY: 4}}>
                <Typography variant="h4" align="center" gutterBottom>
                    WHAT YOU'LL EXPERIENCE AT FHNP
                </Typography>

                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" gutterBottom>
                            At Family Health NP Practice (FHNP), you will experience an atmosphere that is comfortable
                            and relaxing in a home-like setting. Patients have the opportunity to communicate with the
                            practitioner in a private setting and at their own pace. The practice is located in an
                            environment that does not create anxiety and fear. There is no complex medical equipment and
                            personnel that often create anxiety among patients. Here, you’ll be greeted by a friendly
                            staff then the practitioner. Because Dr. Whyte is an advanced registered nurse practitioner,
                            she possesses the capabilities of performing nursing tasks; eliminating the number of people
                            patients see between providers.
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            This experience is extremely important for our patients who are anxious, private, and for
                            patients who get easily annoyed by repeating themselves to multiple staff. FHNP has
                            eliminated the 10-15 minutes wait time patients have become accustomed to at other doctors'
                            offices, where the focus is on the numbers.
                        </Typography>

                        <Collapse in={showMore}>
                            <Typography variant="body1" gutterBottom>
                                At FHNP, our focus is on our patients and promoting quality time with patients and
                                providers to establish healthy patient-provider relationships. By allowing our patients
                                to set their own time with self-schedule, it is evident that FHNP puts patients’
                                interests above ours. FHNP’s self-schedule feature allows patients to manage the amount
                                of time spent with the provider. This embraces the practice’s motto of “Health care with
                                No Parameters, Anywhere, Anytime”! Dr. Whyte is available to patients at any time, even
                                when the doors are closed patients can call/text/send messages via our secured portal.
                            </Typography>
                        </Collapse>

                        <Button variant="contained" color="primary" onClick={handleToggle} sx={{mt: 2}}>
                            {showMore ? 'SHOW LESS' : 'SHOW MORE'}
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <img src={services} alt="Experience at FHNP"
                             style={{width: '100%', height: 'auto'}}/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default About;
