import React, { useState } from 'react';
import { Container, Typography, Button, Box, Grid, TableRow, TableCell, TableBody, Table, TableContainer, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import heroImg from '../images/bg/bg4.jpeg';
import services from '../images/general/services.jpg';

import KareoModal from "../components/KareoModal";
import ServicesOffered from "../components/ServicesOffered";

function Home() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* Banner Section */}
            <Box
                sx={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${heroImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: '300px', md: '600px' },
                    color: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    paddingX: 2,
                }}
            >
                <Typography variant="h4" component="div" gutterBottom sx={{ fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
                    Healthcare with No Parameters; Anywhere! Anytime!
                </Typography>
                <Typography variant="h6" component="div" gutterBottom sx={{ pl: { xs: 2, md: 5 }, pr: { xs: 2, md: 5 }, fontSize: { xs: '1rem', md: '1.25rem' } }}>
                    "FHNP strives to provide a place where patients and families can receive health care in a private and comfortable setting at patients own pace."
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => navigate('/about')} sx={{ mr: 2, width: { xs: '100%', md: 'auto' }, mb: { xs: 2, md: 0 } }}>
                        LEARN MORE
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleOpen} sx={{ width: { xs: '100%', md: 'auto' } }}>
                        MAKE APPOINTMENT
                    </Button>
                </Box>
            </Box>

            <ServicesOffered />

            {/* Contact Us Section */}
            <Box sx={{ padding: { xs: 2, md: 4 } }}>
                <Typography variant="h4" align="center" gutterBottom sx={{ mt: 5, mb: 5, fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
                    HOURS OF OPERATION
                </Typography>
                <Grid container spacing={4} sx={{ height: '100%' }}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ height: { xs: '300px', md: '100%' } }}>
                            <iframe
                                title="Google Maps"
                                src="https://maps.google.com/maps?q=484%20Brooks%20Avenue%2C%20Suite%204%2C%20Rochester%20NY%2014619%20&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                style={{ width: '100%', height: '100%', border: 0 }}
                                allowFullScreen
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ padding: 2 }}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        {[
                                            { day: 'Monday', hours: '9AM - 4:30PM' },
                                            { day: 'Tuesday', hours: '9AM - 4:30PM' },
                                            { day: 'Wednesday', hours: '9AM - 4:30PM' },
                                            { day: 'Thursday', hours: '9AM - 4:30PM' },
                                            { day: 'Friday', hours: '9AM - 4:30PM' },
                                            { day: 'Saturday', hours: '10AM - 4:00PM' },
                                            { day: 'Sunday', hours: 'Closed' },
                                            { day: 'Holidays', hours: 'Closed Healthcare/Service industry' },
                                        ].map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.day}</TableCell>
                                                <TableCell>{row.hours}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Insurances Accepted Section */}
            <Box sx={{ textAlign: 'center', padding: { xs: 2, md: 4 }, backgroundColor: '#f9f9f9' }}>
                <Typography variant="h4" align="center" gutterBottom sx={{ mt: 5, mb: 5, fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
                    INSURANCES ACCEPTED
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    {['Excellus/BCBS', 'United Health Care', 'Medicaid', 'Aetna', 'Self-Pay'].map((insurance, index) => (
                        <Grid item key={index} xs={12} sm={6} md={2}>
                            <Typography variant="body1" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>{insurance}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* What You'll Experience Section */}
            <Container>
                <Typography variant="h4" align="center" gutterBottom sx={{ mt: 5, mb: 5, fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
                    WHAT YOU'LL EXPERIENCE AT FHNP
                </Typography>
            </Container>

            <Box sx={{ padding: { xs: 2, md: 4 } }}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                                At Family Health NP Practice (FHNP), you will experience an atmosphere that is comfortable and relaxing in a home-like setting. Patients have the opportunity to communicate with the practitioner in a private setting and at their own pace. The practice is located in an environment that does not create anxiety and fear. There is no complex medical equipment and personnel that often create anxiety among patients. Here, you’ll be greeted by a friendly staff then the practitioner. Because Dr. Whyte is an advanced registered nurse practitioner, she possesses the capabilities of performing nursing tasks; eliminating the number of people patients see between providers. This experience is extremely important for our patients who are anxious, private, and for patients who get easily annoyed by repeating themselves to multiple staff. FHNP has eliminated the 10-15 minutes wait time patients have become accustomed to at other doctors' offices, where the focus is on the numbers.
                            </Typography>
                            <Button variant="contained" color="primary" sx={{ mt: 2, width: { xs: '100%', md: 'auto' } }} href="/about">
                                LEARN MORE
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={services} alt="Experience at FHNP" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                </Grid>
            </Box>

            <KareoModal open={open} handleClose={handleClose} />
        </div>
    );
}

export default Home;
