import React from 'react';
import {Container, Button, Typography, Box, Grid, CardContent, Card, TextField} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Print'; // Using Print icon for Fax
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

function Contact() {
  return (
      <>
        {/* Jumbotron Section */}
        <Box sx={{ backgroundColor: '#d3d2d2', padding: '50px 0' }}>
          <Container>
            <Typography variant="h2" align="center" gutterBottom>
              CONTACT
            </Typography>
            <Typography variant="h5" align="center">
              FAMILY HEALTH NURSING PRACTICE
            </Typography>
          </Container>
        </Box>

        {/* Contact Form Section */}
        <section id="contact">
          <Container sx={{ paddingY: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" align="center" sx={{ maxWidth: '75%', margin: '0 auto' }}>
              Leave a detailed message and a member of our friendly staff will respond within the next 24 - 48 hours.
            </Typography>
            <Box component="form" id="contact-form" method="post" action="/contact" role="form" sx={{ marginTop: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      id="form_name"
                      name="name"
                      label="First name"
                      required
                      placeholder="Please enter your firstname *"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      id="form_lastname"
                      name="surname"
                      label="Last name"
                      required
                      placeholder="Please enter your lastname *"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      id="form_email"
                      name="email"
                      type="email"
                      label="Email"
                      required
                      placeholder="Please enter your email *"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      fullWidth
                      id="form_phone"
                      name="phone"
                      type="tel"
                      label="Phone"
                      placeholder="Please enter your phone"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      fullWidth
                      id="form_message"
                      name="message"
                      label="Message"
                      required
                      multiline
                      rows={4}
                      placeholder="Message for me *"
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button variant="contained" color="primary" type="submit">
                    SEND
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <strong>*</strong> These fields are required.
              </Typography>
            </Box>

            {/* Contact Information Cards */}
            <Grid container spacing={4} sx={{ marginTop: 5 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ border: 'none', boxShadow: 'none', textAlign: 'center' }}>
                  <CardContent>
                    <PhoneIcon sx={{ fontSize: 60 }} />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                      Call Us
                    </Typography>
                    <Typography variant="body1">(585) 309-1745</Typography>
                    <Typography variant="body1">(585) 464-8618</Typography>
                    <Typography variant="body1">(585) 309-1745</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ border: 'none', boxShadow: 'none', textAlign: 'center' }}>
                  <CardContent>
                    <FaxIcon sx={{ fontSize: 60 }} />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                      Fax
                    </Typography>
                    <Typography variant="body1">(585) 464-8626</Typography>
                    <Typography variant="body1">(949) 404-6331</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ border: 'none', boxShadow: 'none', textAlign: 'center' }}>
                  <CardContent>
                    <LocationOnIcon sx={{ fontSize: 60 }} />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                      Office Location
                    </Typography>
                    <Typography variant="body1">
                      484 Brooks Avenue, Suite 4, Rochester NY 14619
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ border: 'none', boxShadow: 'none', textAlign: 'center' }}>
                  <CardContent>
                    <EmailIcon sx={{ fontSize: 60 }} />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                      Email
                    </Typography>
                    <Typography variant="body2">familycarenursingpractice@outlook.com</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>

          {/* Book Appointment Section */}
          <Box sx={{ textAlign: 'center', marginTop: 5 }}>
            <Typography variant="h5" className="miniheading">
              BOOK APPOINTMENT NOW
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <a
                  href="https://www.zocdoc.com/practice/family-health-np-practice-61609"
                  className="zd-plugin"
                  datatype="book-button"
                  data-practice-id="61609"
                  title="Family Health NP Practice"
              >
                <img
                    src="https://offsiteSchedule.zocdoc.com/images/remote/zd_bookonline_162x48.png"
                    alt="Family Health NP Practice"
                    title="Family Health NP Practice"
                    style={{ border: 0 }}
                />
              </a>
            </Box>
            <script
                dangerouslySetInnerHTML={{
                  __html: `(function (d) {var script = d.createElement('script'); script.type = 'text/javascript'; script.async = true; script.src = 'https://offsiteschedule.zocdoc.com/plugin/embed';var s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);})(document);`,
                }}
            />
          </Box>
        </section>
      </>
  );
}

export default Contact;
