import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, IconButton, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
import Logo from "../images/logol.png";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
      <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button component={Link} to="/home">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/about">
            <ListItemText primary="About" />
          </ListItem>
          <ListItem button component={Link} to="/services">
            <ListItemText primary="Services" />
          </ListItem>
          <ListItem button component={Link} to="/faq">
            <ListItemText primary="FAQs" />
          </ListItem>
          <ListItem button component={Link} to="/contact">
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button component={Link} to="/appointment">
            <ListItemText primary="Schedule an Appointment" />
          </ListItem>
        </List>
      </Box>
  );

  return (
      <div>
        {/* Top strip with contact information */}
        <Box sx={{ bgcolor: '#004c83', p: 1, color: "#ffffff" }}>
          <Container maxWidth="xl">
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <PlaceIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography variant="body2">
                  484 Brooks Avenue, Suite 4, Rochester NY 14619
                </Typography>
                <EmailIcon sx={{ fontSize: 18, ml: 2, mr: 1 }} />
                <Typography variant="body2">familycarenursingpractice@outlook.com</Typography>
              </Box>
              <Box display="flex" alignItems="center" flexWrap="wrap" mt={{ xs: 1, md: 0 }}>
                <PublicIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography variant="body2">fhnursingpractice.com</Typography>
                <PhoneIcon sx={{ fontSize: 18, ml: 2, mr: 1 }} />
                <Typography variant="body2">585-309-1745</Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        {/* Navbar */}
        <AppBar position="static" color="default" sx={{ backgroundColor: "#ffffff" }}>
          <Container maxWidth="xl">
            <Toolbar>
              {/* Brand */}
              <Link to="/home">
                <img src={Logo} alt="Logo" width="200px" />
              </Link>
              {/* Toggler/collapsible Button */}
              <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ ml: 'auto', display: { xs: 'block', md: 'none' } }}
                  onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              {/* Navbar links */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'flex-end' }}>
                <Button component={Link} to="/home" color="inherit">Home</Button>
                <Button component={Link} to="/about" color="inherit">About</Button>
                <Button component={Link} to="/services" color="inherit">Services</Button>
                <Button component={Link} to="/faq" color="inherit">FAQs</Button>
                <Button component={Link} to="/contact" color="inherit">Contact</Button>
                <Button color="primary" variant="contained" component={Link} to="/appointment" sx={{ ml: 2 }}>
                  Schedule an Appointment
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Drawer for mobile menu */}
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
        >
          {drawerList}
        </Drawer>
      </div>
  );
}

export default Header;
