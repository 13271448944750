import React from 'react';
import { Box, Typography, Grid, TextField, Button, Divider, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import sbaImage from '../images/sba.jpg';
import nyMinorityImage from '../images/nyminority.jpg';
import instagramIcon from '../images/footer/001-instagram.png';
import twitterIcon from '../images/footer/002-twitter.png';
import facebookIcon from '../images/footer/003-facebook.png';
import linkedinIcon from '../images/footer/004-linkedin.png';

function Footer() {
    return (
        <Box sx={{ bgcolor: '#004c83', p: 4, color: '#ffffff' }}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={2} />

                <Grid item xs={12} sm={8}>
                    <Typography variant="h6" align="center" gutterBottom sx={{ color: '#ffffff' }}>
                        SUBSCRIBE TO GET LATEST NEWS UPDATES AND HEALTH TIPS
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <TextField
                            variant="outlined"
                            placeholder="Enter your email to subscribe to our newsletter"
                            sx={{ flexGrow: 1, maxWidth: 400, mr: 2, bgcolor: '#ffffff' }}
                        />
                        <Button variant="contained" color="primary">
                            SUBSCRIBE
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2} />
            </Grid>

            <Grid container spacing={4} sx={{ mt: 4 }}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" sx={{ color: '#ffffff' }}>SERVICES AND TREATMENT</Typography>
                    <MuiLink component={Link} to="/services#immunization" variant="body2" display="block" sx={{ color: '#ffffff' }}>Immunization</MuiLink>
                    <MuiLink component={Link} to="/services#chm" variant="body2" display="block" sx={{ color: '#ffffff' }}>Chronic Health Management</MuiLink>
                    <MuiLink component={Link} to="/services#mmc" variant="body2" display="block" sx={{ color: '#ffffff' }}>Medical Marijuana Certification</MuiLink>
                    <MuiLink component={Link} to="/services#std" variant="body2" display="block" sx={{ color: '#ffffff' }}>STD Screening</MuiLink>
                    <MuiLink component={Link} to="/services#mac" variant="body2" display="block" sx={{ color: '#ffffff' }}>Minor Acute Care</MuiLink>
                    <MuiLink component={Link} to="/services#hc" variant="body2" display="block" sx={{ color: '#ffffff' }}>Home Care</MuiLink>
                    <MuiLink component={Link} to="/services#house" variant="body2" display="block" sx={{ color: '#ffffff' }}>House Calls</MuiLink>
                    <MuiLink component={Link} to="/services#smoke" variant="body2" display="block" sx={{ color: '#ffffff' }}>Smoke Cessation Counseling</MuiLink>
                    <MuiLink component={Link} to="/services#telehealth" variant="body2" display="block" sx={{ color: '#ffffff' }}>Telehealth</MuiLink>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" sx={{ color: '#ffffff' }}>About FNHP</Typography>
                    <MuiLink component={Link} to="/about" variant="body2" display="block" sx={{ color: '#ffffff' }}>Staff</MuiLink>
                    <MuiLink component={Link} to="/about" variant="body2" display="block" sx={{ color: '#ffffff' }}>What to Expect</MuiLink>
                    <MuiLink component={Link} to="/contact" variant="body2" display="block" sx={{ color: '#ffffff' }}>Contact Info</MuiLink>
                    <MuiLink component={Link} to="/about" variant="body2" display="block" sx={{ color: '#ffffff' }}>Our Mission</MuiLink>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" sx={{ color: '#ffffff' }}>Billing Resources</Typography>
                    <MuiLink component={Link} to="/faq" variant="body2" display="block" sx={{ color: '#ffffff' }}>Payment Info</MuiLink>
                    <MuiLink component={Link} to="/home" variant="body2" display="block" sx={{ color: '#ffffff' }}>Insurances Accepted</MuiLink>
                    <MuiLink component={Link} to="/contact" variant="body2" display="block" sx={{ color: '#ffffff' }}>Billing Questions</MuiLink>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Typography variant="h6" sx={{ color: '#ffffff' }}>Site Tools</Typography>
                    <MuiLink component={Link} to="/services" variant="body2" display="block" sx={{ color: '#ffffff' }}>Sitemap</MuiLink>
                    <MuiLink component={Link} to="/privacy" variant="body2" display="block" sx={{ color: '#ffffff' }}>Privacy Policy</MuiLink>
                    <MuiLink href="https://www.hhs.gov/hipaa/index.html" variant="body2" display="block" sx={{ color: '#ffffff' }}>HIPAA</MuiLink>
                </Grid>
            </Grid>

            <Divider sx={{ my: 4, borderColor: '#ffffff' }} />

            <Grid container spacing={4} sx={{ textAlign: 'center' }}>
                <Grid item xs={6} sm={2}>
                    <img src={sbaImage} alt="SBA" width="100px" />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <img src={nyMinorityImage} alt="NY Minority" width="200px" />
                </Grid>
            </Grid>

            <Divider sx={{ my: 4, borderColor: '#ffffff' }} />

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <MuiLink href="https://www.instagram.com/fhnursingpractice/">
                                <img src={instagramIcon} alt="Instagram" />
                            </MuiLink>
                        </Grid>
                        <Grid item>
                            <MuiLink href="https://twitter.com/np_practice">
                                <img src={twitterIcon} alt="Twitter" />
                            </MuiLink>
                        </Grid>
                        <Grid item>
                            <MuiLink href="https://www.facebook.com/FamilyCareNP">
                                <img src={facebookIcon} alt="Facebook" />
                            </MuiLink>
                        </Grid>
                        <Grid item>
                            <MuiLink href="https://www.linkedin.com/in/dr-beverly-whyte-dnp-aprn-fnp-bc-30659261/">
                                <img src={linkedinIcon} alt="LinkedIn" />
                            </MuiLink>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography variant="body2" align="center" sx={{ color: '#ffffff' }}>
                        &copy; Copyright 2020 Family Health Nursing Practice. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;
