
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function Privacy() {
  return (
    <Box sx={{ mt: 4 }}>
      <Container>
        <Typography variant="h2" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is where you can outline your privacy policy, explaining how you collect, use, and protect user data.
        </Typography>
      </Container>
    </Box>
  );
}

export default Privacy;
