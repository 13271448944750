
import React from 'react';
import {Container, Typography, Box, Grid} from '@mui/material';

function FAQ() {
  return (
      <>
        {/* Jumbotron Section */}
        <Box sx={{ backgroundColor: '#d3d2d2', padding: '50px 0' }}>
          <Container>
            <Typography variant="h2" align="center" gutterBottom>
              FREQUENTLY ASKED QUESTIONS
            </Typography>
            <Typography variant="h5" align="center">
              FAMILY HEALTH NURSING PRACTICE
            </Typography>
          </Container>
        </Box>

        {/* FAQ Section */}
        <Container sx={{ paddingY: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: How long is a typical visit?
                </Typography>
                <Typography variant="body1">
                  A: Length of visits are determined by visit types, acute or chronic conditions, or routine follow-ups. For example, annual physicals usually take between 45-60 minutes compared to a routine follow-up that takes 15-30 minutes.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: Am I seen by a Doctor?
                </Typography>
                <Typography variant="body1">
                  A: Yes. Dr. Beverly Whyte is a Doctor in Nursing Practice. She is licensed and certified in the State of New York to diagnose, assess, and treat medical conditions in family health similar to a family doctor with an MD.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: Are there after-hour visits?
                </Typography>
                <Typography variant="body1">
                  A: Yes. Family Health NP Practice is open on Saturdays, and patients can schedule Telehealth visits that fall outside normal regular hours.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: What information do I need to provide when scheduling an appointment?
                </Typography>
                <Typography variant="body1">
                  A: You’ll need your health insurance information and any other method of payment.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: What should I bring with me to my appointment?
                </Typography>
                <Typography variant="body1">
                  A: Your health insurance card, driver’s license, non-driver’s license, or any state-issued identification card. Your medicines or the medication list, and any relevant information pertaining to the visit.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: How early should I arrive at my appointment?
                </Typography>
                <Typography variant="body1">
                  A: At least 10-15 minutes before your scheduled time.
                </Typography>
              </Box>

              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Q: What is the “no-show” policy?
                </Typography>
                <Typography variant="body1">
                  A: If a patient does not show up to their appointment and did not cancel at least 24 hours in advance, the insurance will be billed as a “missed appointment.”
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
  );
}

export default FAQ;
