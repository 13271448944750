import React, { useState } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import ServiceCard from './ServiceCard';
import injectionIcon from '../images/icons/001-injection.png';
import stethoscopeIcon from '../images/icons/002-stethoscope.png';
import prescriptionIcon from '../images/icons/003-prescription.png';
import medicalTestIcon from '../images/icons/001-medical-test.png';
import healthInsuranceIcon from '../images/icons/004-health-insurance.png';
import firstAidKitIcon from '../images/icons/006-first-aid-kit.png';
import healthcareIcon1 from '../images/icons/007-healthcare-1.png';
import doctorIcon from '../images/icons/001-doctor.png';
import noSmokingIcon from '../images/icons/001-no-smoking.png';
import healthcareIcon2 from '../images/icons/005-healthcare.png';

function ServicesOffered() {
    const [open, setOpen] = useState({
        immunization: false,
        chm: false,
        mmc: false,
        std: false,
        mac: false,
        pha: false,
        homecare: false,
        housecalls: false,
        scc: false,
        telehealth: false,
    });

    const handleOpen = (service) => {
        setOpen({ ...open, [service]: true });
    };

    const handleClose = (service) => {
        setOpen({ ...open, [service]: false });
    };

    return (
        <Container>
            <Typography variant="h3" align="center" gutterBottom sx={{mt : 5, mb :5}}>SERVICES OFFERED</Typography>
            <Grid container spacing={4}>
                <ServiceCard
                    icon={injectionIcon}
                    title="IMMUNIZATION"
                    description="Access to adults and children's routine vaccines. For example, FLU vaccine, Varicella, Pneumococcal, Shingles vaccine etc."
                    open={open.immunization}
                    handleOpen={() => handleOpen('immunization')}
                    handleClose={() => handleClose('immunization')}
                />
                <ServiceCard
                    icon={stethoscopeIcon}
                    title="CHRONIC HEALTH MANAGEMENT"
                    description="Manage a wide variety of chronic medical conditions such as diabetes, hypertension, hypothyroidism, Asthma/COPD, Osteoarthritis, etc."
                    open={open.chm}
                    handleOpen={() => handleOpen('chm')}
                    handleClose={() => handleClose('chm')}
                />
                <ServiceCard
                    icon={prescriptionIcon}
                    title="MEDICAL MARIJUANA CERTIFICATION"
                    description="Eligible candidates can become certified via a simple process. We offer in-depth natural pain management with the use of medical marijuana."
                    open={open.mmc}
                    handleOpen={() => handleOpen('mmc')}
                    handleClose={() => handleClose('mmc')}
                />
                <ServiceCard
                    icon={medicalTestIcon}
                    title="STD SCREENING"
                    description="We provide screening for Gonorrhea, Chlamydia, Syphilis and other STDs. Quick, rapid results on common STDs such as HIV."
                    open={open.std}
                    handleOpen={() => handleOpen('std')}
                    handleClose={() => handleClose('std')}
                />
                <ServiceCard
                    icon={healthInsuranceIcon}
                    title="MINOR ACUTE CARE"
                    description="Quick access to care for minor issues like sprains, minor lacerations, ear infections, sinusitis, etc. No need to wait in an ER."
                    open={open.mac}
                    handleOpen={() => handleOpen('mac')}
                    handleClose={() => handleClose('mac')}
                />
                <ServiceCard
                    icon={firstAidKitIcon}
                    title="PHYSICAL HEALTH ASSESSMENT"
                    description="In-depth examination and analysis of physical health."
                    open={open.pha}
                    handleOpen={() => handleOpen('pha')}
                    handleClose={() => handleClose('pha')}
                />
                <ServiceCard
                    icon={healthcareIcon1}
                    title="HOME CARE"
                    description="Special home care service providing one-on-one care to elderly patients, adults, and children who have special care needs."
                    open={open.homecare}
                    handleOpen={() => handleOpen('homecare')}
                    handleClose={() => handleClose('homecare')}
                />
                <ServiceCard
                    icon={doctorIcon}
                    title="HOUSE CALLS"
                    description="Our house call program brings a medical provider right to your house. Ideal for elderly people or during emergencies."
                    open={open.housecalls}
                    handleOpen={() => handleOpen('housecalls')}
                    handleClose={() => handleClose('housecalls')}
                />
                <ServiceCard
                    icon={noSmokingIcon}
                    title="SMOKING CESSATION COUNSELING"
                    description="Treatment and counseling for smoking cessation."
                    open={open.scc}
                    handleOpen={() => handleOpen('scc')}
                    handleClose={() => handleClose('scc')}
                />
                <ServiceCard
                    icon={healthcareIcon2}
                    title="TELEHEALTH"
                    description="Quick access to a general practitioner through tele audio and video features for treatment and health management."
                    open={open.telehealth}
                    handleOpen={() => handleOpen('telehealth')}
                    handleClose={() => handleClose('telehealth')}
                />
            </Grid>
        </Container>
    );
}

export default ServicesOffered;
