import React from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function ServiceCard({ icon, title, description, open, handleOpen, handleClose }) {
    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardActionArea onClick={handleOpen}>
                        <CardMedia
                            component="img"
                            image={icon}
                            alt={title}
                            sx={{ height: 80, width: 80, margin: '0 auto', objectFit: 'contain' }}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: title.length > 20 ? '0.9rem' : '1.25rem' }}>
                                {title}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

            <Dialog open={open} onClose={handleClose} aria-labelledby={`${title}-modal`}>
                <DialogTitle id={`${title}-modal`}>{title}</DialogTitle>
                <DialogContent>
                    <Typography>{description}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ServiceCard;
